// Adjust top to account for fixed header
.app-main__inner .pm-table thead {
  top: 60px !important;
}

.findigs-v3 {
  @at-root {
    html#{&} {
      background-color: var(--findigs-BrandPrimary-Sand);
      // The below is a global change that is purposefully being retained for tracking / git blame in case of regression:
      //height: 100%;
      color: black;
    }
  }

  @import '~/node_modules/@findigs/components/src/styles/app';
  @import 'header';

  // Main site specific fixes
  .form-control {
    height: unset;
  }

  // Main site specific fixes
  .card-footer {
    padding-top: 0 !important;
    padding-bottom: 1.75rem !important;
  }

  .trinity-dropdown-menu {
    padding: 0.5rem 0;
    animation: none;
    min-width: 197px;
  }
  .container-margin {
    margin: 0 70px;
    @include media-breakpoint-down(md) {
      margin: 0 24px;
    }
  }
}

.findigs-v3-floop {
  @import '~/node_modules/@findigs/components/src/styles/app-floop';
  @import 'header';

  @at-root {
    html#{&} {
      background-color: $brandPrimarySand;
      // The below is a global change that is purposefully being retained for tracking / git blame in case of regression:
      //height: 100%;
      color: $brandPrimaryForest;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div {
    font-family: $findigs-sans !important;
  }

  .tenant-header .dropdown-menu-header {
    background-color: $brandPrimaryForest;
  }

  .form-control-wrapper {
    // Main site specific fixes
    .form-control {
      height: 3.75rem;
      background-color: $brandPrimarySand !important;
      border-color: $tintsNeutral30;
      font-family: $findigs-sans;
      &:hover {
        border-color: $tintsNeutral50;
      }
      &.is-invalid {
        color: $utilityError60;
        border-color: $utilityError60;
        box-shadow: none;
      }
    }

    .error-message {
      color: $utilityError60;
      font-size: 0.875rem;
    }
  }

  .dropdown-wrapper {
    > div > div {
      border-color: $tintsNeutral30;

      &:hover,
      &:focus {
        border-color: $tintsNeutral50;
      }
    }
  }

  .value-container {
    > div,
    > div div {
      background-color: $brandPrimarySand !important;
      //border-color: $tintsNeutral30;

      &:hover,
      &:focus {
        border-color: $tintsNeutral50;
      }
    }

    > div {
      height: 3.75rem;
      padding: 0 !important;
      > div {
        padding-left: 1.1rem;
      }
    }
  }

  // Main site specific fixes
  .card-footer {
    padding-top: 0 !important;
    padding-bottom: 1.75rem !important;
  }

  .trinity-dropdown-menu {
    padding: 0.5rem 0;
    animation: none;
    min-width: 197px;
  }
  .container-margin {
    margin: 0 70px;
    @include media-breakpoint-down(md) {
      margin: 0 24px;
    }
  }
  .btn-link {
    color: $brandPrimaryForest;
  }

  div.react-datepicker-popper {
    .react-datepicker {
      background-color: $brandPrimarySand;
      font-weight: 400 !important;
      &__header {
        background-color: $brandPrimarySand !important;
      }
      &__day {
        &:hover {
          background-color: $brandPrimaryForest;
          color: $brandPrimarySand;
        }
        &--selected {
          color: $brandPrimaryForest;
          background-color: $tintsNeutral30;
        }
        &--disabled {
          color: $tintsNeutral50;
        }
      }
      &__navigation {
        &:hover {
          background-color: $brandPrimaryForest;
          color: $brandPrimarySand;
        }
      }
    }
  }

  div.topic-card {
    background-color: $tintsNeutral10;
    border: none;

    &.success {
      border: 0.0625rem solid $brandPrimaryForest !important;
      div.card-title {
        color: $brandPrimaryForest !important;
      }
      svg path {
        color: $brandPrimaryForest !important;
        stroke: $brandPrimaryForest !important;
      }
    }

    div.card-body {
      color: $tintsNeutral80 !important;

      div.card-header {
        background-color: $tintsNeutral10;
        border: none;
        color: $brandPrimaryForest !important;
      }

      div.card-subtitle,
      p.card-text {
        color: $tintsNeutral80 !important;
      }

      div.card-footer {
        background-color: $tintsNeutral10;
        border: none;

        .topic-button {
          text-decoration: underline;
          text-underline-offset: 0.375rem;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    &.stacked-card {
      background-color: $brandPrimarySand;
      border: 0.125rem solid $utilitySuccess60;
      .card-body {
        .card-header,
        .card-footer {
          background-color: $brandPrimarySand !important;
          color: $utilitySuccess60 !important;
        }
      }
    }
  }

  div.file-drop-div {
    background-color: $brandPrimarySand;

    > div {
      background-color: $brandPrimarySand;
      color: $brandPrimaryForest !important;
      border: none;
      &:hover {
        border: $tintsNeutral10;
      }
      &[role='button'] p {
        color: $brandPrimaryForest !important;
      }
    }
    div.file-list {
      div.file-container {
        background-color: $brandPrimarySand;

        div.dropdown-wrapper {
          > div > div > div {
            font-size: 0.875rem;
          }
        }
      }
      p.upload_warning_text {
        font-size: 0.875rem;
      }
    }
  }

  label.choice-label {
    background-color: $brandPrimarySand;
    border-color: $tintsNeutral30;
    color: $brandPrimaryForest;

    &:hover {
      border-color: $brandPrimaryForest;
    }
    &:active {
      background-color: $brandPrimaryForest !important;
      border-color: $brandPrimaryForest !important;
      color: $brandPrimarySand !important;
    }
    &:disabled {
      border-color: $tintsNeutral30 !important;
      color: $tintsNeutral30 !important;
    }
  }

  button.footer-link {
    background-color: $brandPrimarySand;
  }

  form.id-upload-form {
    div.idv-file-upload {
      div[role='button'] {
        border-color: $brandPrimaryForest;
        background-color: $tintsNeutral10;
        color: $brandPrimaryForest;
      }
    }

    .file-name {
      border-color: $utilitySuccess60;
      color: $utilitySuccess60;
    }
  }

  .file-upload-card,
  .file-upload-card-multi {
    border: none;
    background-color: $tintsNeutral10;
    color: $brandPrimaryForest;

    &:hover {
      border: none !important;
      background-color: $tintsNeutral10 !important;
      color: $brandPrimaryForest !important;
    }
  }

  .docUploadsForm-verbose {
    font-family: $findigs-sans !important;
    span {
      font-family: $findigs-sans !important;
    }
  }

  .modal-content {
    background-color: $brandPrimarySand;
    font-family: $findigs-sans !important;

    span {
      font-family: $findigs-sans !important;
    }
    .modal-title {
      svg {
        circle {
          fill: $brandPrimarySand !important;
        }
        path {
          fill: $utilityWarning60 !important;
        }
      }
    }
    .modal-footer {
      background-color: $brandPrimarySand;

      .btn-light {
        background-color: $neutral30;
      }
    }
  }

  .file-name {
    border-color: $utilitySuccess60;
    color: $utilitySuccess60;
  }

  .warning-text-container {
    background-color: $utilityWarning10 !important;
    font-family: $findigs-sans !important;
    font-weight: 400;

    .app-pending-bold,
    b {
      font-weight: 500;
    }
  }

  .alert-text-container {
    background-color: $utilityError10 !important;
    &.alert {
      background-color: $utilityError10 !important;
    }
    &.warning {
      background-color: $utilityWarning10 !important;
    }
  }

  .loader-icon {
    color: $brandPrimaryForest !important;
  }

  .alert {
    color: $brandPrimaryForest !important;
    background-color: $utilityWarning10;
    &-info {
      background-color: $utilityInfo10;
    }
    &-warning {
      background-color: $utilityWarning10;
    }
    &-success {
      background-color: $utilitySuccess10;
    }
    &-error {
      background-color: $utilityError10;
    }
  }

  .signCanvas {
    background-color: $brandPrimarySand;
    border-color: $brandPrimaryForest;
    &.--error {
      border-color: $utilityError60;
    }
  }

  .StripeElement,
  .payment-input {
    background-color: $brandPrimarySand !important;
    border-color: $tintsNeutral30 !important;

    &--invalid {
      border-color: $utilityError60 !important;
    }

    &--disabled {
      background-color: $tintsNeutral30 !important;
    }
  }
}
