.UserAvatar {
  display: inline-block;
  color: var(--findigs-BrandPrimary-Sand);
}

.UserAvatar--inner {
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UserAvatar--light {
  color: var(--findigs-BrandPrimary-Forest);
}

.UserAvatar--light .UserAvatar--inner {
  border: 1px solid var(--findigs-BrandPrimary-Sand);
}
