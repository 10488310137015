element {
  --red: rgba(204, 30, 30);
  --green: rgba(22, 171, 79);
}

/* Error Alert */
.error .swal-button {
  background-color: var(--red);
}

.error .swal-text {
  border-color: var(--red);
}

.error .swal-title {
  color: var(--red);
}

/* Success Alert */
.success .swal-button {
  background-color: var(--green);
}

.success .swal-text {
  border-color: var(--green);
}

.success .swal-title {
  color: var(--green);
}

/* Warning Alert */
.warning .swal-button {
  background-color: var(--orange);
}

.warning .swal-text {
  border-color: var(--orange);
}

.warning .swal-title {
  color: var(--orange);
}

/* Constants/Default */
.swal-overlay {
  background-color: rgba(33, 34, 37, 0.849);
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  font-size: 12px;
  border-radius: 20%;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  background-color: var(--findigs-BrandPrimary-Forest);
}

.swal-text {
  display: block;
  margin: 20px;
  text-align: center;
  padding: 20px;
  color: rgba(97, 83, 78);
  border: 1px solid;
}
