// TODO: Fix this up to directly use UX's defined styles.
$pm-base-spacing: 4px;
$font-weight: 400;
$bold-font-weight: 700;
$title-font-size: 48px;
$sub-title-font-size: 24px;
$sub-title-font-size-20: 20px;
$body-font-size: 16px;
$label-font-size: 13px;
$title-line-height: 80px;
$sub-title-line-height: 36px;
$body-line-height: 24px;
$label-line-height: 20px;
$title-letter-spacing: -0.0025em;
$subTitle-letter-spacing: -0.005em;
$body-letter-spacing: -0.004em;
$label-letter-spacing: -0.002em;
$font-style: 'normal';
$pm-font-family: 'Findigs Sans';
$neutral100: #212529;
$neutral90: #343a40;
$neutral80: #495057;
$neutral75: #66707a;
$neutral70: #868e96;
$neutral60: #adb5bd;
$neutral50: #ced4da;
$neutral35: #e5e5e5;
$neutral30: #e9ecef;
$primaryWhite: #fcfcfc;
$neutral20: #f1f3f5;
$neutral40: #dee2e6;
$neutral10: #f8f9fa;
$blueTint30: #bce4f5;
$blueTint50: #63c0e8;
$blueTint60: #37aee1;
$brandTint10: #e9effe;
$brandTint20: #d1ddfc;
$brandTint40: #89a7f8;
$brandTint60: #2960f2;
$brandTint70: #0d44d6;
$brandTint80: #0b34a1;
$greenTint10: #d1f3e6;
$red20: #ffe1db;
$red10: #fef0f0;
$redTint40: #ffa28e;
$error10: #ffede8;
$secondaryRed: #ff4f28;
$secondaryOrange: #f58b2c;
$secondaryYellow: #ffcc16;
$secondaryBlue: #37aee1;
$secondaryGreen: #33c58d;
$secondaryPurple: #b222f6;
$yellow20: #fff3c8;

$purple30: #e5b5fc;
$purple50: #c353f8;

$green20: #bdeedb;
$green30: #a8e9d0;
$green50: #57d4a4;

$pmNeutral80: #2b3036;

$orange10: #fef6ef;

$firstAdvantageBlue: #002f87;
