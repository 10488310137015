@import './LandlordColors.scss';

.landlord.container {
  .col-12 {
    margin-top: 15px;
  }

  .past-housing-card {
    margin-top: 15px;
    background-color: var(--findigs-Tints-Neutral20);
    padding: 20px;
    border-radius: 10px;

    @extend %print-only-border-outline;
  }

  .rent-amount-column {
    width: 100%;
    display: flex;
    flex-flow: row;
    margin-bottom: 10px;
    margin-top: 15px;

    @extend %print-only-border-outline;

    .self-reported {
      flex-grow: 1;
      flex-basis: 32%;
      padding: 15px;
      padding-left: 20px;
      border-radius: 10px 0px 0px 10px;
      background-color: $self-reported-background;
    }

    .bank-insights {
      flex-grow: 2;
      flex-basis: 68%;
      padding: 15px;
      padding-left: 20px;
      background-color: $findigs-analyzed-background;
    }

    .column-end {
      width: 15px;
      border-radius: 0px 10px 10px 0px;
      background-color: $status-successful;
    }
  }

  .housing-history-card {
    margin-bottom: 10px;
    border-radius: 10px;

    @extend %print-only-border-outline;
    h3 {
      color: var(--findigs-BrandPrimary-Forest);
      font-weight: 600;
    }

    &.self-reported {
      background-color: $self-reported-background;
      height: calc(100% - 22px);

      .top {
        padding: 20px;
        padding-bottom: 10px;
        padding-top: 26px;
      }

      .bottom {
        background-color: $self-reported-background;
        border-top: 1px solid white;
        padding: 20px 15px 5px 20px;
        color: black;
        border-radius: 0px 0px 10px 10px;
      }
    }

    &.analyzed {
      background-color: $findigs-analyzed-background;
      height: calc(100% - 22px);

      .top {
        padding: 20px;
        padding-bottom: 10px;
        padding-top: 26px;
        height: calc(100% - 20px);
      }

      .bottom {
        background-color: $partially-successful;
        padding: 5px;
        color: white;
        border-radius: 0px 0px 10px 10px;

        @include status-colors;
      }

      .question-button {
        background-color: $id-button-colors;
        width: 30%;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border: none;
        border-radius: 10px;

        &:disabled {
          opacity: 0.7;
        }

        &:not(:disabled) {
          &:active,
          &:hover,
          &:focus {
            background-color: darken($id-button-colors, 10%);
          }
        }
      }
    }
  }
}
