@import './LandlordColors.scss';

$table-background-color: var(--findigs-Tints-Neutral20);
$table-gray-font-color: var(--findigs-BrandPrinary-Forest);

.landlord.container {
  .bankStatementsData {
    margin-top: 15px;
    background-color: $self-reported-background;
    padding: 20px;
    border-radius: 10px;

    h5 {
      font-weight: bold;
    }
  }

  .incomesummary {
    .col-12 {
      margin-bottom: 15px;
    }
  }

  .monthly-income-table-container {
    height: 420px;
    max-height: 420px;
    overflow-y: auto;

    &.show-overflow {
      min-height: 420px;
      max-height: 100%;
      height: 100%;
    }
  }

  .monthly-income-table {
    width: 100%;

    tr {
      border-bottom: 1px solid lightgray;

      td {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 146.52%;
        color: #8c8c8c;
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .source-number {
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 50%;
    font-size: 14px;
    color: white;
    text-align: center;
    background: $findigs-blue;
    display: inline-block;
    margin-right: 8px;
  }

  .chart-wrapper {
    .bank-insight-button {
      margin-left: 15px;
      font-size: 16px;
      display: inline-block;
      margin-right: 0px;
      font-weight: bold;
      color: #8c8c8c;
      border: unset;
      background: unset;
      &.selected {
        font-weight: bold;
        color: #3e3e3e;
      }
    }
    @extend %print-only-border-outline;
  }

  .ReactTable.incomesummary {
    @media only screen {
      box-shadow: 0px 4px 15px rgba(200, 200, 200, 0.25);
    }
    @media only print {
      border: 1px solid $print-border-color;
      border-radius: 10px 10px 0px 0px;
    }

    background-color: $table-background-color;
    max-height: 510px;

    &.show-overflow {
      max-height: 100%;
    }

    .rt-thead .rt-th:hover,
    .ReactTable .rt-thead .rt-td:hover {
      color: darken(white, 20%);
    }

    .rt-thead {
      background-color: $status-successful;
      color: white;
      font-family: Inter;
      font-size: 16px;
      line-height: 146.52%;
      border-radius: 10px 10px 0px 0px;

      .rt-thead,
      .rt-th {
        padding: 1rem;
      }
    }

    .rt-tbody .rt-tr-group {
      border-bottom: 1px solid rgba(140, 140, 140, 0.1);
    }

    .rt-td {
      background-color: $table-background-color;
      color: $table-gray-font-color;
      font-size: 14px;
      font-weight: 500;
      padding: 20px 10px;
      padding-left: 17px;
    }

    .rt-tfoot {
      box-shadow: unset;

      .rt-td {
        border-right: none;
        border-radius: 0px 0px 10px 10px;
      }

      .rt-td:nth-child(1),
      .rt-td:nth-child(2) {
        color: #797979;
        font-size: 16px;
        font-weight: bold;
      }

      .rt-td:nth-child(3) {
        color: $findigs-blue;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
