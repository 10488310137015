@import './LandlordColors.scss';

.landlord.container {
  .workflow-status {
    background-color: white;
    padding: 10px 10px;
    display: flex;
    margin-left: auto;

    .label {
      margin-right: 10px;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      margin-top: 2px;
      color: var(--findigs-BrandPrimary-Forest);
    }

    .input-wrapper {
      border-left: 1px solid #e5e5e5;
      flex-grow: 1;
      input {
        margin-left: 10px;
        border: none;
        width: calc(100% - 10px);
        border-bottom: 2px solid transparent;

        &:focus,
        &:active {
          outline: none;
          border-bottom: 2px solid var(--findigs-BrandPrimary-Forest);
        }
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  .landlord.container {
    .workflow-status {
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px;
      flex: 1 20px;
    }
  }
}
