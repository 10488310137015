.applicant-incomplete-blocker {
  .block-ui-container {
    box-shadow: 0px 0px 0px 10px rgba(20, 20, 20, 0.75);
    cursor: default;
  }
  .block-ui-overlay {
    background-color: black;
    opacity: 0.7;
  }
}
