@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import './pmvariables';
@import './colors-floop';

/** Old Colors **/

$findigs-blue: #0f73c0;
$findigs-blue-20-opacity: rgba(15, 115, 192, 0.2);
$findigs-blue-50-opacity: rgba(15, 115, 192, 0.5);
$findigs-blue-70-opacity: rgba(15, 115, 192, 0.7);
$findigs-light-blue: #5481df;
$findigs-dark-blue: #16467d;
$findigs-self-reported-blue: #e9eef8;
$almost-black-10-opacity: #0514251a;
$almost-black: #051425;
$almost-white: #f5f5fa;
$full-white: #ffffff;
$full-black: #000000;

$findigs-gray: #4e5865;
$findigs-gray-2: rgba(5, 20, 37, 0.5);
$findigs-gray-light: #848a98;
$findigs-gray-very-light: #c5c8cf;
$primary-green: #087e6b;
$primary-green-20-opacity: rgba(8, 126, 107, 0.2);
$primary-green-50-opacity: rgba(8, 126, 107, 0.5);
$primary-alert-yellow: #ffa640;
$primary-alert-yellow-20-opacity: rgba(255, 166, 64, 0.2);
$secondary-alert-yellow: rgba(255, 166, 64, 0.5);
$dark-alert-yellow: #f1972f;
$error-red: #b43b45;
$error-red-20-opacity: rgba(180, 59, 69, 0.2);
$secondary-alert-red: rgba(180, 59, 69, 0.5);
$info-blue: var(--findigs-Tints-Neutral20);
$info-text-blue: var(--findigs-BrandPrimary-Forest);

$inter: 'Inter';
$inter-bld-font-weight: 700;
$inter-semi-bld-font-weight: 600;
$inter-med-font-weight: 500;
$inter-sm-font-weight: 400;

$font-size-landing: 56px;
$font-size-landing-sub: 36px;
$font-size-header: 28px;
$font-size-subheader: 20px;
$font-size-mobile-subheader: 16px;
$font-size-lg-cta: 22px;
$font-size-med-cta: 18px;
$font-size-lg-body: 16px;
$findigs-15-body: 15px;
$font-size-med-body: 14px;
$font-size-eyebrows: 12px;
$font-size-status: 10px;
$font-size-tooltip-body: 10px;

$line-height-100: 100%;
$line-height-110: 110%;
$line-height-125: 125%;

$letter-spacing-3: -0.03em;
$letter-spacing-5: -0.05em;
$base-spacing: 8px;
$findigs-colors: (
  'blue': $findigs-blue,
  'indigo': $findigs-dark-blue,
  'red': $error-red,
  'yellow': $primary-alert-yellow,
  'green': $primary-green,
  'white': $full-white,
  'almost-white': $almost-white,
  'black': $full-black,
  'almost-black': $almost-black,
  'gray': $findigs-gray-light,
  'gray-light': $findigs-gray-very-light,
  'gray-dark': $findigs-gray,
  'pm-gray': $neutral40,
);

/** These variables will overwrite bootstrap colors */
$findigs-theme-colors: (
  'primary': $brandPrimaryForest,
  'secondary': map-get($findigs-colors, 'gray-light'),
  'success': map-get($findigs-colors, 'green'),
  'info': map-get($findigs-colors, 'pm-gray'),
  'warning': map-get($findigs-colors, 'yellow'),
  'danger': map-get($findigs-colors, 'red'),
  'light': map-get($findigs-colors, 'white'),
  'dark': map-get($findigs-colors, 'almost-black'),
);

$primary: $brandPrimaryForest;
$secondary: map-get($findigs-colors, 'gray-light');
$success: map-get($findigs-colors, 'green');
$info: map-get($findigs-colors, 'gray');
$warning: map-get($findigs-colors, 'yellow');
$danger: map-get($findigs-colors, 'red');
$light: map-get($findigs-colors, 'white');
$dark: map-get($findigs-colors, 'almost-black');

$findigs-sans: 'Findigs Sans', system-ui, -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
