@import 'pmvariables';
.page-title {
  font-family: $pm-font-family;
  font-style: $font-style;
  font-size: $title-font-size;
  line-height: $title-line-height;
  letter-spacing: $title-letter-spacing;
}

.page-sub-title {
  font-family: $pm-font-family;
  font-style: $font-style;
  font-size: $sub-title-font-size;
  line-height: $sub-title-line-height;
  letter-spacing: $subTitle-letter-spacing;
  font-weight: $font-weight;
}

.page-sub-title-20 {
  font-family: $pm-font-family;
  font-style: $font-style;
  font-size: $sub-title-font-size-20;
  line-height: $sub-title-line-height;
  letter-spacing: $subTitle-letter-spacing;
  font-weight: $font-weight;
}

.body-text {
  font-family: $pm-font-family;
  font-style: $font-style;
  font-size: $body-font-size;
  line-height: $body-line-height;
  letter-spacing: $body-letter-spacing;

  &.bold-text {
    font-weight: $bold-font-weight;
  }
}
.label-text {
  font-family: $pm-font-family;
  font-style: $font-style;
  font-size: $label-font-size;
  line-height: $body-line-height;
  letter-spacing: $label-letter-spacing;
}
.label-text-small {
  font-family: $pm-font-family;
  font-style: $font-style;
  font-size: $label-font-size;
  line-height: $label-line-height;
  letter-spacing: $label-letter-spacing;
  font-weight: $bold-font-weight;
}

.sentence-case {
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

.capital-case {
  text-transform: uppercase;
}
