@import '~bootstrap/scss/mixins/buttons';
@import '../pmvariables';
@import '../variables-floop';

.pm-button {
  &.btn-md {
    @include button-size(
      $padding-y: 0.75rem,
      $padding-x: 1.5rem,
      $font-size: 16px,
      $border-radius: 4px
    );
  }
}

// This has to be defined here and not in the style module for Button b/c
// all the generated class names would be hashed.
.btn {
  opacity: 1;
  border-radius: 0.75rem;
  padding: 0.8125rem 1.5rem;
  font-family: $findigs-sans;

  &-primary {
    @include button-variant(
      $brandPrimaryForest,
      $brandPrimaryForest,
      $hover-background: $tintsNeutral90,
      $active-background: $tintsNeutral90
    );
    &:active {
      background-color: $tintsNeutral90 !important;
    }
  }

  &-pm-primary {
    @include button-variant(
      $brandPrimaryForest,
      $brandPrimaryForest,
      $hover-background: $tintsNeutral90,
      $active-background: $tintsNeutral80
    );
    &:focus {
      background-color: $brandPrimaryForest;
      outline: 2px solid $brandPrimaryForest !important;
    }
    &:active {
      background-color: $brandPrimaryForest;
      outline: none !important;
    }
    &:disabled {
      background-color: $tintsNeutral40;
      border-color: $tintsNeutral40;
      color: $brandPrimaryForest;
    }
  }

  &-secondary {
    @include button-variant(
      $brandPrimaryForest,
      $brandPrimaryForest,
      $hover-background: $tintsNeutral90,
      $active-background: $tintsNeutral90
    );
  }

  &-outline-primary {
    &:focus,
    &:hover {
      color: $brandPrimarySand !important;
      background-color: $tintsNeutral90 !important;
      border-color: $tintsNeutral90 !important;
    }
    &:active {
      background-color: $brandPrimaryForest !important;
      border-color: $brandPrimaryForest !important;
    }
    background-color: $brandPrimarySand;
  }

  &-outline-secondary {
    @include button-outline-variant(
      $color: $neutral100,
      $color-hover: $neutral80,
      $active-background: $neutral10,
      $active-border: $neutral80
    );

    // TODO: Make this apart of a sass function.
    &:focus {
      background-color: $brandPrimaryForest;
    }

    &:disabled {
      background-color: $brandPrimarySand !important;
      color: $neutral60 !important;
      border-color: $neutral40 !important;
    }
  }

  &:disabled:not(.btn-link),
  &:disabled:not(.btn-link-nav),
  &.disabled:not(.btn-link) {
    @include button-variant(
      $tintsNeutral20,
      $tintsNeutral20,
      $color: $tintsNeutral60
    );
  }

  &-link {
    @include button-variant(
      transparent,
      transparent,
      $color: $brandPrimaryForest,
      $hover-background: transparent,
      $hover-color: $tintsNeutral80,
      $disabled-color: map-get($colors, 'gray-dark'),
      $disabled-background: transparent
    );
    padding: unset;
  }

  &.circle-button {
    border-radius: 50%;
    padding: 2px 6px 2px 6px;
    font-size: $font-size-lg-cta;
    width: 40px;
    height: 40px;
  }

  &-link-nav {
    @include button-variant(
      $background: $tintsNeutral10,
      $border: transparent,
      $color: $brandPrimaryForest,
      $hover-background: $tintsNeutral90,
      $hover-color: $tintsNeutral10,
      $disabled-color: $tintsNeutral80,
      $disabled-background: $tintsNeutral10,
      $active-background: $tintsNeutral10,
      $active-color: $tintsNeutral80
    );
  }
  &-link-transparent {
    @include button-variant(
      $background: transparent,
      $border: transparent,
      $color: $brandPrimaryForest,
      $hover-background: $tintsNeutral90,
      $hover-color: $brandPrimarySand,
      $disabled-color: $tintsNeutral80,
      $disabled-background: $tintsNeutral10,
      $active-background: $tintsNeutral10,
      $active-color: $tintsNeutral80
    );
  }
}
