@import './LandlordFonts.scss';
@import './WorkflowStatus.scss';
@import './GroupInsights.scss';
@import './DecisionCalculator.scss';
@import './ApplicantsTab.scss';
@import './Applicant.scss';
@import './ApplicantIdentity.scss';
@import './ApplicantAccounts.scss';
@import './ApplicantPastJobs.scss';
@import './ApplicantHousing.scss';
@import './LandlordColors.scss';
@import './IncomeSummary.scss';
@import './IncomeSource.scss';
@import './DisclosureAndDependents.scss';
@import './SupplementaryInfo.scss';
@import './SingleApplicantCard.scss';
@import './ApplicantCards.scss';
@import './LandlordPrint.scss';

.newLandlordUI {
  &.app-container {
    background: var(--findigs-BrandPrimary-Sand) !important;
  }

  .app-main__inner {
    background-color: var(--findigs-BrandPrimary-Sand) !important;
  }
}

.landlord.container {
  .no-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .color {
    &-findigs-blue {
      color: var(--findigs-BrandPrimary-Forest);
    }

    &-dark-gray {
      color: var(--findigs-BrandPrimary-Forest);
    }

    &-trinity-secondary-gray {
      color: var(--findigs-Tints-Neutral80);
    }
  }

  a {
    &:focus,
    &:active,
    &:hover {
      text-decoration: underline;
    }
  }

  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Findigs Sans', 'Inter', Arial, Helvetica, sans-serif;

    &.subtitle {
      font-family: 'IBM Plex Mono', Arial, Helvetica, sans-serif;
    }
  }

  p {
    font-size: 14px;
    line-height: 148.5%;
    margin-bottom: 0;
  }

  h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 112.19%;
    color: var(--findigs-BrandPrimary-Forest);
  }

  button.buttonAsLink {
    border: none;
    color: var(--findigs-BrandPrimary-Forest);
    background-color: transparent;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: var(--findigs-Tints-Neutral100);
    }
  }

  button {
    font-weight: bold;

    &.decline-approval {
      padding: 15px 45px;
      display: inline-block;
      border: none;
      border-radius: 10px;
    }

    &.blue {
      background-color: var(--findigs-BrandPrimary-Forest);
      color: white;

      &:hover,
      &:focus,
      &:active {
        background-color: var(--findigs-Tints-Neutral20);
      }
    }

    &.white {
      background-color: var(--findigs-BrandPrimary-Sand);
      color: var(--findigs-BrandPrimary-Forest);
      border: 2px solid var(--findigs-BrandPrimary-Forest);

      &:hover,
      &:focus,
      &:active {
        background-color: var(--findigs-Tints-Neutral20);
      }
    }

    &.gray {
      background-color: var(--findigs-BrandPrimary-Sand);
      color: gray;
      border: 2px solid gray;

      &:hover,
      &:focus,
      &:active {
        &:not(:disabled) {
          background-color: var(--findigs-Tints-Neutral20);
        }
      }
    }

    &.red {
      background-color: var(--findigs-BrandPrimary-Sand);
      color: red;
      border: 2px solid red;
    }

    &.no-styles {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .font-weight-semibold {
    font-weight: 600 !important;
  }
}

@media print {
  div.newLandlordUI.app-container {
    background: var(--findigs-BrandPrimary-Sand) !important;
  }

  .app-header,
  .app-sidebar {
    display: none !important;
  }

  .fixed-sidebar .app-main .app-main__outer {
    padding-left: 0 !important;
  }

  .fixed-header .app-main {
    padding-top: 0 !important;
  }

  #launcher {
    display: none !important;
  }
}
