/** New colors **/

$brandPrimaryKeyGreen: #8ede65;
$brandPrimaryForest: #212b14;
$brandPrimarySand: #fdfbf7;

$brandSecondaryJade: #22c386;
$brandSecondarySky: #add2ff;
$brandSecondarySun: #ffce59;
$brandSecondaryCoral: #ffa27a;
$brandSecondaryBerry: #f3b3f1;
$brandSecondaryPebble: #ddc8c9;

$tintsNeutral100: #333331;
$tintsNeutral90: #4d4c4a;
$tintsNeutral80: #666563;
$tintsNeutral70: #807f7c;
$tintsNeutral60: #999894;
$tintsNeutral50: #b2b1ad;
$tintsNeutral40: #cccbc6;
$tintsNeutral30: #dbdad5;
$tintsNeutral20: #ebe9e4;
$tintsNeutral10: #f5f4ee;

$tintsKeyGreen100: #52982c;
$tintsKeyGreen90: #60aa38;
$tintsKeyGreen80: #72bc4a;
$tintsKeyGreen70: #7fcd56;
$tintsKeyGreen60: #8ede65;
$tintsKeyGreen50: #9eec76;
$tintsKeyGreen40: #aff58b;
$tintsKeyGreen30: #c1faa4;
$tintsKeyGreen20: #cffdb8;
$tintsKeyGreen10: #dbffc9;

$tintsJade100: #1c8c62;
$tintsJade90: #1f996b;
$tintsJade80: #22a775;
$tintsJade70: #24b57e;
$tintsJade60: #22c386;
$tintsJade50: #3ad198;
$tintsJade40: #56dba9;
$tintsJade30: #6fe8ba;
$tintsJade20: #98f3d1;
$tintsJade10: #adf5da;

$tintsSky100: #709bd1;
$tintsSky90: #7daae1;
$tintsSky80: #8bb7ed;
$tintsSky70: #9cc4f5;
$tintsSky60: #add2ff;
$tintsSky50: #bbd9ff;
$tintsSky40: #c7e0ff;
$tintsSky30: #d7e8ff;
$tintsSky20: #e2efff;
$tintsSky10: #ebf4ff;

$tintsSun100: #d29e21;
$tintsSun90: #dea82b;
$tintsSun80: #e7b236;
$tintsSun70: #f2c047;
$tintsSun60: #ffce59;
$tintsSun50: #ffd677;
$tintsSun40: #ffde93;
$tintsSun30: #ffe5a9;
$tintsSun20: #ffeaba;
$tintsSun10: #fff0ce;

$tintsCoral100: #d6612f;
$tintsCoral90: #e46d3a;
$tintsCoral80: #f17e4d;
$tintsCoral70: #f98d5f;
$tintsCoral60: #ffa27a;
$tintsCoral50: #ffb798;
$tintsCoral40: #ffc6ae;
$tintsCoral30: #ffd1be;
$tintsCoral20: #ffdbcc;
$tintsCoral10: #ffe4da;

$tintsBerry100: #c078be;
$tintsBerry90: #d082cd;
$tintsBerry80: #de92db;
$tintsBerry70: #eca4e9;
$tintsBerry60: #f3b3f1;
$tintsBerry50: #f5c2f3;
$tintsBerry40: #fccdfa;
$tintsBerry30: #fdd5fb;
$tintsBerry20: #fdddfb;
$tintsBerry10: #ffe8fd;

$tintsPebble100: #b9a6a7;
$tintsPebble90: #c2aeaf;
$tintsPebble80: #ccb6b7;
$tintsPebble70: #d4bfc0;
$tintsPebble60: #ddc8c9;
$tintsPebble50: #e5d0d0;
$tintsPebble40: #ecd9d9;
$tintsPebble30: #f0dfdf;
$tintsPebble20: #f6e8e8;
$tintsPebble10: #fcf0f0;

$utilitySuccess60: #2d7e20;
$utilitySuccess10: #e3fed5;

$utilityWarning60: #e3640d;
$utilityWarning10: #fff1d1;

$utilityError60: #c13e06;
$utilityError10: #ffede8;

$utilityInfo60: #2a86db;
$utilityInfo10: #ebf4ff;

$overlaysDark8: rgba(51, 51, 49, 0.08);
$overlaysDark16: rgba(51, 51, 49, 0.16);
$overlaysDark24: rgba(51, 51, 49, 0.24);
$overlaysDark75: rgba(33, 43, 20, 0.75);

$overlaysLight8: rgba(253, 251, 247, 0.08);
$overlaysLight16: rgba(253, 251, 247, 0.16);
$overlaysLight24: rgba(253, 251, 247, 0.24);
$overlaysLight75: rgba(253, 251, 247, 0.75);
