:global(.findigs-v3-floop) {
  // unbreaking swal
  &.swalFixes :global(.swal-button) {
    border-radius: 5px !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    padding: 10px 24px !important;
  }

  .page,
  .title {
    font-family: Findigs Sans, system-ui, -apple-system, BlinkMacSystemFont,
      Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Helvetica Neue, sans-serif !important;
    letter-spacing: normal !important;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ss02' on !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-variant-ligatures: normal !important;
    font-kerning: normal !important;
    text-rendering: optimizeLegibility !important;
    text-rendering: geometricPrecision !important;
  }

  .page {
    padding: 34px 24px 0 24px !important;
    max-width: 100% !important;
  }

  .title {
    margin: 0 !important;

    margin-bottom: #{48px - 24px} !important;
    font-size: 1.75rem !important;
    line-height: 2.125rem !important;
    font-weight: 900 !important;
    color: #212b14 !important;
  }

  .cta {
    width: 100% !important;
    margin-top: 48px;
  }

  .logo {
    max-width: 110px !important;
    max-height: 60px !important;

    margin-bottom: 64px !important;
  }

  .form {
    margin: 0 !important;
    padding: 0 !important;
  }

  @media (min-width: 768px) {
    .page {
      padding: 34px 32px 0 32px !important;
    }

    .cta {
      width: auto !important;
      min-width: 120px !important;
    }

    .content {
      display: grid !important;
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .title {
      grid-column: auto/span 4;
      grid-column-start: 2;

      font-size: 2.5rem !important;
      line-height: 2.875rem !important;
      font-weight: 900 !important;
    }

    .form {
      grid-column: auto/span 5;
      grid-column-start: 7;
      margin-top: -20px !important;
    }
  }

  @media (min-width: 1024px) {
    .page {
      padding: 48px 32px 0 32px !important;
    }

    .logo {
      max-width: 135px !important;
      max-height: 40px !important;
    }

    .content {
      grid-template-columns: repeat(16, minmax(0, 1fr));
    }

    .title {
      grid-column: auto/span 5;
      grid-column-start: 3;
    }

    .form {
      grid-column: auto/span 5;
      grid-column-start: 10;
    }
  }

  @media (min-width: 1440px) {
    .content {
      grid-template-columns: repeat(24, minmax(0, 1fr));
    }

    .title {
      grid-column: auto/span 6;
      grid-column-start: 4;
    }

    .form {
      grid-column: auto/span 7;
      grid-column-start: 15;
    }
  }
}
