.landlord.container {
  .identity-analysis {
    color: #4e5865;
    &.pass {
      color: #087e6b;
    }
    &.fail {
      color: #b43b45;
    }
    &.manual_review {
      color: #a95408;
    }
  }
}
