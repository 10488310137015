@import './LandlordColors.scss';

.applicant-cards-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

  .in-progress {
    color: $partially-successful;
  }

  .invited {
    color: $status-successful;
  }
}
