@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap');
.Landing-Page {
  font-family: 'Inter';
  font-weight: $inter-bld-font-weight;
  font-size: $font-size-landing;
  line-height: $line-height-100;
  letter-spacing: $letter-spacing-5;
}

.Landing-Page-Subtext {
  font-family: 'Inter';
  font-weight: $inter-bld-font-weight;
  font-size: $font-size-landing-sub;
  line-height: $line-height-125;
  letter-spacing: $letter-spacing-5;
}

.Header-Text {
  font-family: 'Inter';
  font-weight: $inter-bld-font-weight;
  font-size: $font-size-header;
  line-height: $line-height-125;
  letter-spacing: $letter-spacing-5;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-lg-body;
  }

  &.PM-UI-28 {
    line-height: 94% !important;
  }
}

.Subheader-Text {
  font-family: 'Inter';
  font-weight: $inter-bld-font-weight;
  font-size: $font-size-subheader;
  line-height: $line-height-125;
  letter-spacing: $letter-spacing-3;
}
.Large-CTA {
  font-family: 'Inter';
  font-weight: $inter-semi-bld-font-weight;
  font-size: $font-size-lg-cta;
  line-height: $line-height-100;
  letter-spacing: $letter-spacing-3;
}
.Med-CTA {
  font-family: 'Inter';
  font-weight: $inter-semi-bld-font-weight;
  font-size: $font-size-med-cta;
  line-height: $line-height-100;
  letter-spacing: $letter-spacing-3;
}
.Standard-Body {
  font-family: 'Inter';
  font-weight: $inter-med-font-weight;
  font-size: $font-size-lg-body;
  line-height: $line-height-125;
  letter-spacing: $letter-spacing-3;
  @include media-breakpoint-down(lg) {
    font-size: $font-size-eyebrows;
  }
}

.Med-Body {
  font-family: 'Inter';
  font-weight: $inter-med-font-weight;
  font-size: $font-size-med-body;
  line-height: $line-height-125;
  letter-spacing: $letter-spacing-3;
  &.PM-UI-14 {
    line-height: 94% !important;
  }
}

.Legal-Copy {
  font-family: 'Inter';
  font-weight: $inter-med-font-weight;
  font-size: $font-size-med-body;
  line-height: $line-height-125;
  letter-spacing: $letter-spacing-3;
}

.Eyebrow-Text {
  font-family: 'Inter';
  font-weight: $inter-semi-bld-font-weight !important;
  font-size: $font-size-eyebrows;
  line-height: $line-height-125;
  letter-spacing: $letter-spacing-3;

  &.PM-Pill {
    font-size: $font-size-status !important;
  }
}

.Tooltip-Body-Text {
  font-family: 'Inter';
  font-weight: $inter-semi-bld-font-weight;
  font-size: $font-size-tooltip-body;
  line-height: 165%;
  letter-spacing: $letter-spacing-3;
}

.Status-Text {
  font-family: 'Inter';
  font-weight: $inter-semi-bld-font-weight;
  font-size: $font-size-status;
  line-height: $line-height-125;
  letter-spacing: $letter-spacing-3;
}

.Tooltip-Body-Text {
  font-family: 'Inter';
  font-weight: $inter-semi-bld-font-weight;
  font-size: $font-size-tooltip-body;
  line-height: 165%;
  letter-spacing: $letter-spacing-3;
}

.PM-UI-12 {
  font-family: 'Inter';
  font-weight: $inter-med-font-weight;
  font-size: $font-size-eyebrows;
  line-height: $line-height-110;
  letter-spacing: $letter-spacing-3;

  &.bold {
    font-weight: 700;
  }
}

.PM-UI-14-bold {
  font-family: 'Inter';
  font-weight: $inter-bld-font-weight;
  font-size: $font-size-med-body;
  line-height: $line-height-110;
  letter-spacing: $letter-spacing-3;
}

.PM-UI-18 {
  font-family: 'Inter';
  font-weight: $inter-bld-font-weight;
  font-size: $font-size-med-cta;
  line-height: $line-height-110;
  letter-spacing: $letter-spacing-3;
}

.sentence-case {
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

.capital-case {
  text-transform: uppercase;
}
