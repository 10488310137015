// Variables

$spacer-lg: 3rem;
$spacer-sm: 1.5rem;

$layout-spacer-lg: 3rem;
$layout-spacer-x: 2rem;

$app-sidebar-width: 270px;
$app-sidebar-min-width: 180px;
$app-sidebar-width-collapsed: 80px;
$app-sidebar-width-mobile: 100%;

$logo-height: 23px;
$logo-width: 97px;

$app-header-height: 88px;
