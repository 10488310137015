.applicant-chat {
  margin-bottom: 40px;

  .chat-wrapper {
    max-height: calc(100vh - 325px);
    min-height: 200px;
    overflow-y: scroll;
    background-color: #fefefe;
    border: 1px solid lightgray;
    margin-top: 10px;
    padding: 5px;
    margin-bottom: 10px;

    button {
      font-weight: bold;
    }

    .chat-box-wrapper.chat-box-wrapper-right .chat-box {
      //   background-color: #1589ff;
      color: white;
    }

    .chat-box-wrapper .chat-box {
      color: #495057;
    }
  }
}
