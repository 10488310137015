@import './LandlordColors.scss';
@import '../../../../../../workspaces/libraries/components/src/styles/variables';

.transaction-modal.modal-dialog {
  .modal-body {
    padding: 0;
  }
}

/** Useful for when a header has a label alongside it. */
.header-with-alert {
  display: flex;
  flex-direction: horizontal;

  svg {
    margin-right: 8px;
  }

  .alert {
    border: 2px solid black;
    height: 56px;
    border-radius: 8px;
    font-family: 'IBM Plex Mono', Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin-left: 8px;
    margin-bottom: 0.5rem;
    display: inline-flex;
    align-items: center;

    @media (min-width: 600px) {
      height: 28px;
    }
  }

  h6 + .alert {
    font-size: 10px;
  }
}

.ReactTable.transactions {
  .rt-thead .rt-th:hover,
  .ReactTable .rt-thead .rt-td:hover {
    color: darken(white, 20%);
  }

  .rt-thead {
    background-color: $status-successful;
    color: white;
    font-family: Inter;
    font-size: 16px;
    line-height: 146.52%;

    .rt-thead,
    .rt-th {
      padding: 1rem;
    }
  }

  .rt-tbody .rt-tr-group {
    border-bottom: solid 1px lightgray;
  }

  .rt-td {
    background-color: white;
    color: $table-gray-color;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 10px;
    padding-left: 17px;
  }

  .rt-tfoot {
    box-shadow: unset;

    .rt-td {
      border-right: none;
      border-radius: 0px 0px 10px 10px;
    }

    .rt-td:nth-child(1),
    .rt-td:nth-child(2) {
      color: black;
    }

    .rt-td:nth-child(3) {
      color: $findigs-blue;
      font-size: 18px;
    }
  }
}

.landlord.container {
  .income-source-block {
    .income-source-header {
      background-color: $status-successful;
      color: white;
      padding: 10px 15px;
      padding-top: 15px;
      border-radius: 10px 10px 0px 0px;
      margin-bottom: 15px;

      .source-number {
        width: 28px;
        height: 28px;
        line-height: 28px;
        border-radius: 50%;
        font-size: 14px;
        color: $status-successful;
        text-align: center;
        background: white;
        display: inline-block;
        margin-right: 10px;
      }

      h6 {
        display: inline-block;
      }
    }

    .income-source-column-header {
      width: 100%;
      display: flex;
      flex-flow: row;
      margin-bottom: 10px;

      .self-reported {
        flex-grow: 1;
        flex-basis: 33%;
      }

      .bank-insights {
        flex-grow: 2;
        flex-basis: 67%;
      }
    }

    .income-source-columns {
      width: 100%;
      display: flex;
      flex-flow: row;
      margin-bottom: 10px;

      @extend %print-only-border-outline;

      .self-reported {
        flex-grow: 1;
        flex-basis: 32%;
        padding: 15px;
        padding-left: 20px;
        border-radius: 10px 0px 0px 10px;
        background-color: $self-reported-background;
        display: flex;
        align-items: center;
      }

      .bank-insights {
        flex-grow: 2;
        flex-basis: 68%;
        padding: 15px;
        padding-left: 20px;
        background-color: $findigs-analyzed-background;
      }

      .column-end {
        width: 15px;
        border-radius: 0px 10px 10px 0px;
        background-color: $status-successful;
      }
    }

    .view-justification-button {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.03em;
      color: var(--findigs-BrandPrimary-Forest);
      padding-left: 0;
      padding-right: 0;
      margin-top: 16px;
    }
  }

  .reference-card {
    margin-top: 15px;
    background-color: $self-reported-background;
    padding: 20px;
    border-radius: 10px;

    @extend %print-only-border-outline;

    h5 {
      font-weight: bold;
    }

    p {
      line-height: 200%;
    }

    .fraud-copy {
      line-height: 16px;
      max-width: 615px;
    }
  }

  .attached-supporting-documents {
    color: $findigs-gray;
    padding: 35px 22px;

    .card-title {
      padding-top: 10px;
      line-height: 125%;
      letter-spacing: -0.01em;

      width: 64%;

      p {
        font-weight: 500;
        line-height: 16px;
        margin: 8px 0 12px;
        letter-spacing: -0.03em;
      }
    }

    .missing-required-title {
      font-size: 15px;
      line-height: 20px;
      border-bottom: 1px solid #c5c8cf;
      padding-bottom: 26px;
      margin-bottom: 24px;
      margin-top: 60px;

      span.warning-icon {
        margin-right: 10px;
      }
    }

    .table {
      color: $findigs-gray;

      td,
      th {
        font-family: 'Inter';
        font-weight: 500;
        font-size: 14px;

        padding-top: 1.25rem;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        .filename {
          white-space: pre-wrap;
        }
      }
      thead th {
        border: none;
      }
    }

    .upload-button {
      font-family: 'Findigs Sans';
      font-weight: 400;
      font-size: 16px;
      padding: 12px 24px;
      color: $gray-700;
      background: $primaryWhite;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid $gray-300;
    }

    thead {
      border-bottom: 2px solid $findigs-gray-very-light;
    }
    .top-border {
      border-top: 2px solid $findigs-gray-very-light;
    }

    .link-button {
      color: $brandTint60;
    }
  }

  .employment-data-card {
    margin-bottom: 30px;
  }

  .income-source-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;

    .col-12 {
      margin-bottom: 10px;
    }

    .income-source-card {
      margin-bottom: 10px;
      border-radius: 10px;

      &.self-reported {
        background-color: $self-reported-background;
        padding: 20px;
        padding-bottom: 40px;
        padding-top: 46px;
        height: 100%;

        @extend %print-only-border-outline;
      }

      &.analyzed {
        background-color: $findigs-analyzed-background;
        height: 100%;

        @extend %print-only-border-outline;

        .top {
          padding: 20px;
          padding-bottom: 10px;
          padding-top: 26px;
          height: 150px;
        }

        .bottom {
          background-color: $partially-successful;
          padding: 5px;
          color: white;
          border-radius: 0px 0px 10px 10px;
          height: calc(100% - 150px);

          @include status-colors;
        }
      }

      h3 {
        color: var(--findigs-BrandPrimary-Forest);
        font-weight: 600;
      }

      p {
        font-weight: bold;
      }
    }
  }
}

.justification-popup {
  font-family: 'Findigs Sans';
  color: #212529;

  .modal-header {
    align-items: center;
    background: none;
  }

  .modal-body {
    .justification-info {
      font-size: 13px;
      color: #495057;
    }
    .justification-text {
      font-size: 16px;
      color: #868e96;
      background: #fcfcfc;
      border: 1px solid #dee2e6;
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;
      margin: 20px 0 0px;
    }
  }

  .modal-footer {
    background: none;
    display: flex;
    flex-direction: column;

    button {
      margin-top: 8px !important;
      width: 100%;
      font-size: 16px;
      padding: 12px;

      &.btn-outline-secondary:hover {
        color: inherit;
        background-color: inherit;
        border-color: inherit;
      }
    }
  }
}

.document-upload-popup {
  font-family: 'Findigs Sans';
  color: #212529;

  .modal-header {
    align-items: center;
    background: none;
  }
  .modal-content {
    width: 650px;
  }

  .modal-body {
    .file-upload-container {
      font-size: 16px;
      border: 1px dashed #dee2e6;
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;
      margin: 20px 0 0px;
    }
  }

  .modal-footer {
    background: none;
    display: flex;
    flex-direction: column;

    button {
      margin-top: 8px !important;
      width: 100%;
      font-size: 16px;
      padding: 12px;
      background-color: #2960f2;
      color: white;

      &.btn-outline-secondary:disabled {
        color: #adb5bd;
        background-color: #dee2e6;
        border: none;
      }
    }
  }
}
