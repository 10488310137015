
.Social-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 15px;
  text-align: center;
}

.Social-network__share-count {
  margin-top: 3px;
  font-size: 12px;
}

.Social-network__share-button {
  cursor: pointer;
}

.Social-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Social-network__custom-icon {
  width:32px;
  height:32px;
}
