$background-gray: var(--findigs-BrandPrimary-Sand);
$self-reported-background: var(--findigs-Tints-Neutral20);
$findigs-analyzed-background: white;
$findigs-blue: var(--findigs-BrandPrimary-Forest);
$dark-grey: #797979;
$status-successful: #242b39;
$partially-successful: #e69346;
$status-unsuccessful: #a72b1a;
$not-yet-available: #9b9b9b;
$print-border-color: lightgray;
$findigs-red: #b43b45;
$trinity-secondary-gray: #4e5865;

@mixin status-colors {
  &.partially-successful {
    background-color: $partially-successful;
  }

  &.unsuccesful {
    background-color: $status-unsuccessful;
  }

  &.successful {
    background-color: $status-successful;
  }

  &.not-available {
    background-color: $not-yet-available;
  }
}
@mixin status-colors-text {
  & .partially-successful {
    color: $partially-successful;
  }

  & .unsuccesful {
    color: $status-unsuccessful;
  }

  & .successful {
    color: $status-successful;
  }

  & .not-available {
    color: $not-yet-available;
  }
}

// On print mode, we outline the columns to make it easier to read
%print-only-border-outline {
  @media print {
    break-inside: avoid;
    border: 1px solid $print-border-color;
    border-radius: 10px;
  }
}
