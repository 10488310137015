// Globally define based on font-size-eyebrows.
// This makes changing the label size (above input) trivial.
$label-font-size: $font-size-eyebrows;
$label-height: calc(#{14px} + 0.5rem);

.form-control-wrapper {
  position: relative;
  padding-top: $label-height;

  &.form-wrapper {
    padding-top: 10px;
  }

  .error-message {
    color: map-get($theme-colors, 'danger');
    position: absolute;
    top: 6px;
    right: 0;
    padding: 0;
    font-size: $label-font-size;
    white-space: nowrap;
  }

  .footnote-box {
    font-family: 'Findigs Sans';
    font-size: 13px;
    color: map-get($grays, '500') !important;
    display: flex;
    justify-content: flex-end;
  }

  label {
    border: $input-border-width solid transparent;
    line-height: $input-line-height;
    margin: 0;
  }

  label,
  .select + label {
    position: absolute;
    color: map-get($colors, 'almost-black');
    pointer-events: none;
    top: $label-height;
    padding: $input-padding-y $input-padding-x;
    font-size: $input-font-size;
    transition: 200ms ease-out;
    z-index: 3;
  }

  &.lg label,
  &.lg .select + label {
    font-size: $input-font-size-lg;
    padding: $input-padding-y-lg $input-padding-x-lg;
  }

  &.sm label,
  &.sm .select + label {
    font-size: $input-font-size-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
  }

  &.with-border {
    .form-control {
      border-color: $neutral40;
    }

    .form-control::placeholder {
      color: $neutral60;
    }
  }

  .form-control {
    font-size: $input-font-size;
    line-height: $input-line-height;
    box-shadow: $input-box-shadow;
    // Unset bootstraps min-height.
    min-height: unset;

    &-lg {
      font-size: $input-font-size-lg;
    }

    &-sm {
      font-size: $input-font-size-sm;
    }

    &[required] + label:after {
      content: '*';
      display: inline-block;
      margin-left: 0.25rem;
      color: map-get($theme-colors, 'danger');
    }

    &::placeholder {
      color: map-get($colors, 'almost-black');
      transition: color 200ms ease-out;
    }

    &:hover {
      border-color: map-get($colors, 'gray');
    }

    &:focus {
      border-color: map-get($colors, 'almost-black');
    }
    &:disabled {
      background-color: transparent;
      border-color: map-get($colors, 'gray-light');
    }

    //When updating label styling, need to update here
    &:not(.select) {
      &:disabled + label {
        color: map-get($colors, 'nuetral70');
      }
      &:focus + label,
      &:focus::placeholder,
      &:focus:has(:placeholder-shown) {
        color: map-get($colors, 'nuetral70');
      }
      &:focus:not(:placeholder-shown) + label {
        color: map-get($colors, 'almost-black');
      }
      &:not(:focus):not(:placeholder-shown) + label {
        color: map-get($colors, 'nuetral70');
      }
    }

    &.is-invalid {
      color: map-get($theme-colors, 'danger');
      border-color: map-get($theme-colors, 'danger');

      &:not(:focus) + label,
      &:placeholder-shown + label {
        color: map-get($theme-colors, 'danger');
      }
    }
    &:placeholder-shown + label.has-placeholder {
      color: transparent;
    }
    &:not(:placeholder-shown) + label.has-placeholder,
    &:focus {
      &::placeholder {
        opacity: 0.3;
        transition: opacity ease-in-out 200ms;
      }
      + label.has-placeholder {
        transition: color ease-in-out 200ms;
        color: map-get($colors, 'almost-black');
      }
    }
    & + label.has-placeholder,
    &:not(.select):not(:placeholder-shown) + label,
    &.select-checked + label {
      top: 6px;
      border: 0 solid transparent;
      padding: 0;
      font-size: $label-font-size;
      background-color: transparent;
    }

    &.select-checked + label {
      z-index: 1;
    }

    // Browser overrides
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
