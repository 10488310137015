.header-margin {
  margin-bottom: 60px;
}

.tenant-header {
  display: flex;
  margin: 30px 70px 60px 70px;
  align-items: center;
  height: 48px;
  @include media-breakpoint-down(md) {
    margin: 30px 24px;
  }
  &.--no-border {
    border-bottom: none;
    margin-bottom: 0px;
  }

  @include media-breakpoint-up(md) {
    .title-element-left,
    .title-element-center,
    .title-element-right {
      flex: 1 1 0px;
    }
  }

  .btn-link {
    font-size: 16px;
  }

  .btn-link-nav {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .btn-link-transparent {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .profile-icon {
    @include media-breakpoint-down(md) {
      display: none;
    }
    width: 30px;
  }

  .nav {
    padding: 15px 20px;
  }

  .nav.flex-column:not(:last-child) {
    border-bottom: 1px solid #f5f5fa;
  }

  .collapsed-nav {
    width: 30px;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  img {
    width: 30px;
  }
}

.logout-wrapper {
  text-align: right;
  white-space: nowrap;
}

.dropdown-menu {
  padding-bottom: 0px;
}

.dropdown-menu-header {
  background-color: $findigs-blue;
  padding: 20px 15px 20px 15px;
  margin-bottom: 0px;
  font-size: 15px;

  div:first-child {
    width: 15%;
  }

  div:nth-child(2) {
    width: 50%;
  }

  div:nth-child(3) {
    width: 35%;
  }
  .user-type {
    &:first-letter {
      text-transform: capitalize;
    }
  }
}

.dropdown-menu {
  @include media-breakpoint-up(lg) {
    min-width: 440px;
  }
  @include media-breakpoint-down(lg) {
    top: 15% !important;
  }
}
