@import '../variables';

.findigs-datepicker {
  @import '~react-datepicker/dist/react-datepicker';

  .react-datepicker {
    color: $almost-black !important;
    background-color: var(--findigs-BrandPrimary-Sand);
    border-color: $findigs-gray-light;
    border-radius: 0.4rem;
    padding: 20px;
    &__header {
      font-family: $inter;
      background-color: var(--findigs-BrandPrimary-Sand) !important;
      border: 0 !important;
      padding: unset;
    }

    &__current-month {
      text-align: left;
      font-weight: $inter-med-font-weight;
      font-size: $font-size-lg-body;
      margin-bottom: 20px;
    }

    &__day-names {
      font-family: $inter;
      font-size: $font-size-eyebrows;
      margin-bottom: 0;
    }
    &__month {
      font-size: $font-size-eyebrows;
      margin: unset;
    }
    &__week {
      height: 30px;
    }
    &__day-name {
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin: unset;
    }
    &__day {
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin: unset;
      transition: background-color 0.2s, color 0.2s;
      border-radius: 50%;

      &:hover {
        color: $black;
        background-color: $findigs-gray-light;
        border-radius: 50%;
      }

      &--outside-month {
        visibility: hidden;
        color: $findigs-gray-light;
      }

      &--keyboard-selected {
        color: $black;
        background-color: $findigs-gray-light;
        border-radius: 50%;
      }
    }

    &__triangle {
      display: none;
    }

    &__navigation {
      // Override this to be consistent cross site.
      line-height: 1.75rem;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s;
      top: 15px;

      &--previous {
        left: unset;
        right: 45px;
      }
      &--next {
        right: 15px;
      }
      &:hover {
        *:before {
          transition: border-color 0.2s;
          border-color: $full-white;
        }
      }
      &-icon {
        top: 5px;
        &:before {
          border-color: $full-black;
          border-width: 2px 2px 0 0;
          height: 8px;
          width: 8px;
          right: -3px;
        }
        &--next {
          left: 1px;
        }
        &-previous {
          right: -1px;
        }
      }
      &:hover {
        background-color: $findigs-blue;
      }
    }
  }

  button[readonly].btn-link {
    text-decoration: none !important;
    cursor: default !important;
  }
}
