@import './LandlordColors.scss';

$table-gray-color: #8c8c8c;

.landlord.container {
  .ReactTable.linkedaccounts {
    max-height: 430px;
    overflow-y: auto;
    border-radius: 10px 10px 0px 0px;
    page-break-after: always;
    @media print {
      border: 1px solid $print-border-color;
    }

    &.show-overflow {
      max-height: 100%;
    }

    .rt-thead .rt-th:hover,
    .ReactTable .rt-thead .rt-td:hover {
      color: darken(white, 20%);
    }

    .rt-thead {
      background-color: $status-successful;
      color: white;
      font-family: Inter;
      font-size: 16px;
      line-height: 146.52%;

      .rt-thead,
      .rt-th {
        padding: 1rem;
      }
    }

    &.clickable {
      .rt-tbody .rt-tr:not(.-padRow):hover {
        cursor: pointer;
        background-color: var(--findigs-Tints-Neutral10);
      }
    }

    .rt-tbody .rt-tr-group {
      border-bottom: solid 3px #e9ecef;
      background-color: white;
      min-height: 55px;
    }

    .rt-td {
      color: $table-gray-color;
      font-size: 14px;
      font-weight: 500;
      padding: 12px 10px;
      padding-left: 17px;
    }

    .rt-tfoot {
      box-shadow: unset;
      min-height: 50px;
      background-color: white;

      .rt-td {
        border-right: none;
      }

      .rt-td:nth-child(1),
      .rt-td:nth-child(2) {
        color: black;
      }

      .rt-td:nth-child(3) {
        color: $findigs-blue;
        font-size: 18px;
      }
    }
  }
}
