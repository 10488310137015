@import './LandlordColors.scss';

.landlord.container {
  .group-decision-tree-container .col-12 {
    margin-bottom: 15px;
  }

  /*  remove arrows from number fields 

input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  } /  */

  .group-decision-tree {
    background-color: var(--findigs-BrandPrimary-Sand);
    padding: 20px;
    border-radius: 10px;

    .row {
      margin-bottom: 0.5em;
    }

    .col:nth-child(1) {
      color: #6f6f6f;
    }

    .col:nth-child(2) {
      text-align: right;
      font-weight: bold;
    }
  }

  .mesa-calculator {
    .applicant-button {
      border: none;
      border-radius: 10px;
      padding: 5px 10px;
      margin-right: 10px;

      &.selected {
        background-color: $findigs-blue;
        color: white;
        cursor: default;
      }
    }
  }
}
