@import './LandlordColors.scss';

.landlord.container {
  .supplementary-info-wrapper {
    .responses-wrapper {
      max-height: 500px;
      overflow-y: auto;

      &.show-overflow {
        max-height: 100%;
      }

      .questions-and-responses {
        background-color: var(--findigs-BrandPrimary-Sand);
        border-radius: 10px;
        padding: 10px 10px;
        margin-bottom: 15px;
      }
    }
  }
}
