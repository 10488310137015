.pm-dropdown__menu {
  border-radius: 8px;
  border: 1px solid #495057;
  overflow: hidden;

  .pm-dropdown__option-container:not(:first-child) {
    margin-top: 2px;
  }

  .pm-dropdown__group + .pm-dropdown__option-container {
    margin-top: 0;
  }
}
