.singleApplicantCard {
  background: var(--findigs-BrandPrimary-Sand);
  width: 80%;
  border-radius: 25px;

  @media (max-width: 1120px) {
    width: 100%;
  }

  .button-actions {
    position: absolute;
    top: 0px;
    right: 0px;
    font-weight: bold;
    font-size: 11px;
  }

  .card-expand {
    position: relative;
  }

  .button-view-status {
    position: absolute;
    bottom: 5px;
    right: 0px;
  }
}
