@import './LandlordColors.scss';

.landlord.container {
  .disclosure-dependents-wrapper {
    .dependent-item-wrapper {
      .icon {
        position: relative;
        display: inline-block;
        padding: 10px;
        margin-right: 10px;
        background-color: lightgray;

        svg {
          color: $findigs-blue;
        }
      }

      .text {
        display: inline-block;
        vertical-align: top;
      }
    }

    .dependents-section {
      height: 100%;
    }

    .dependents {
      background-color: $self-reported-background;
      padding: 15px;
      border-radius: 10px;
      height: calc(100% - 96px);
      @extend %print-only-border-outline;

      h4 {
        font-weight: bold;
      }

      h6 {
        font-weight: bold;
        margin-bottom: 0;
      }

      .row {
        margin-bottom: 1.5rem;
      }
    }

    .disclosure {
      background-color: $self-reported-background;
      padding: 15px;
      border-radius: 10px;
      height: calc(100% - 96px);
      @extend %print-only-border-outline;

      .row {
        margin-bottom: 0.5rem;

        .question {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 146.52%;
          color: #797979;
        }

        .answer {
          font-weight: bold;
          text-transform: capitalize;

          &.no {
            color: black;
          }

          &.yes {
            color: $partially-successful;
          }
        }
      }
    }
  }
}
