@import './LandlordColors.scss';

$table-gray-color: #8C8C8C;

.landlord.container {
  .ReactTable.pastjobs {

    .rt-thead .rt-th:hover, .ReactTable .rt-thead .rt-td:hover {
      color: darken( white, 20%);
    }

    .rt-thead {
      background-color: $status-successful;
      color: white;
      font-family: Inter;
      font-size: 16px;
      line-height: 146.52%;
      border-radius: 10px 10px 0px 0px;

      .rt-thead, .rt-th{
          padding: 1rem;
      }

    }

    .rt-tbody .rt-tr-group {
        border-bottom: solid 1px lightgray;
    }

    .rt-td{
        background-color: $self-reported-background;
        color: $table-gray-color;
        font-size: 14px;
        font-weight: 500;
        padding: 12px 10px;
        padding-left: 17px;
    }

    .rt-tfoot{

        box-shadow: unset;
        
        .rt-td{
            border-right: none;
            border-radius: 0px 0px 10px 10px;
        }

        .rt-td:nth-child(1), .rt-td:nth-child(2){
            color: black
        }

        .rt-td:nth-child(3){
            color: $findigs-blue;
            font-size: 18px;
        }
    }
  }
}
