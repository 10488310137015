@import './LandlordColors.scss';

.landlord.container {
  .applicants-tab {
    border-bottom: 3px solid $findigs-blue;

    &.no-border {
      border-bottom: none;
    }

    button {
      border-radius: 10px 10px 0px 0px;
      border: none;
      width: 225px;
      padding: 5px;
      margin-right: 10px;
      background-color: var(--findigs-BrandPrimary-Sand);
      color: $findigs-blue;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.refunded {
        color: $findigs-red;
      }

      &:hover {
        background-color: darken(white, 10%);
      }

      &.selected {
        background-color: $findigs-blue;
        color: white;

        &:hover {
          background-color: $findigs-blue;
        }

        &.refunded {
          background-color: $findigs-red;
          color: white;

          &:hover {
            background-color: darken($findigs-red, 10%);
          }
        }
      }
    }
  }
}
