@import './LandlordColors.scss';

.landlord.container {
  .self-reported-row {
    height: calc(100% - 30px);

    .self-reported-col {
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }

  .group-comments {
    background-color: var(--findigs-BrandPrimary-Sand);
    padding: 15px;
    padding-top: 20px;
    border-radius: 10px;
  }

  .group-insight-container {
    @media print {
      border: 1px solid $print-border-color;
      border-radius: 10px;
    }

    h3 {
      color: $findigs-blue;
      font-weight: 600;
    }

    p {
      font-weight: bold;
    }

    &.analyzed {
      margin-bottom: 15px;

      & .top-section {
        background-color: $findigs-analyzed-background;
        width: 100%;
        padding: 20px;
        padding-right: 0px;
        border-radius: 10px 10px 0px 0px;
        height: 120px;
      }

      & .status-section {
        width: 100%;
        padding: 8px;
        border-radius: 0px 0px 10px 10px;
        background-color: $partially-successful;
        color: white;
        min-height: 10px;

        @include status-colors;

        &.placeholder {
          background-color: $findigs-blue;
          animation: fading 1.5s infinite;
        }
      }
    }

    &.self-reported {
      background-color: $self-reported-background;
      padding: 20px;
      padding-top: 40px;
      border-radius: 10px;
      height: 100%;
    }
  }
}
