.placeholder {
  div.placeholder-item {
    animation: fading 1.5s infinite;

    &.blue {
      background-color: var(--findigs-Tints-Neutral30);
    }

    &.gray {
      background-color: var(--findigs-Tints-Neutral30);
    }
  }
}

@keyframes fading {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.1;
  }
}
