@import './LandlordColors.scss';

.landlord.container {
  .landlord-chat {
    background-color: $self-reported-background;
    border-radius: 10px;
    padding: 15px;

    @media print {
      border: 1px solid $print-border-color;
    }
  }

  .chat-wrapper {
    max-height: calc(100vh - 325px);
    min-height: 200px;
    overflow-y: auto;
    background-color: #fefefe;
    margin-top: 10px;
    padding: 5px;
    margin-bottom: 10px;

    &.show-overflow {
      max-height: 100%;
    }

    .chat-box-wrapper.chat-box-wrapper-right .chat-box {
      // background-color: #1589ff;
      color: white;
    }

    .chat-box-wrapper .chat-box {
      color: #495057;
    }

    .chat-box-wrapper {
      @media print {
        break-inside: avoid;
      }
    }
  }
}
