@import './LandlordColors.scss';

$id-button-colors: #ebe9e4;
$primary-green: #087e6b;
$grey: #4e5865;
$error-red: #b43b45;

.landlord.container {
  div.insight-card {
    height: calc(100% - 31px);
    border: none;
    position: relative;
    background-color: var(--findigs-BrandPrimary-Sand);
    @media only screen {
      box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03),
        0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03),
        0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05),
        0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
    }
    @media only print {
      border: 1px solid $print-border-color;
    }
  }

  div.applicant-identity-1 {
    background-color: var(--findigs-Tints-Neutral20);
    padding: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
  }

  .id-buttons div {
    width: calc(33% - 0.5rem);

    button {
      background-color: var(--findigs-Tints-Neutral90);
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      border: none;
      border-radius: 10px;

      &:disabled {
        background-color: var(--findigs-Tints-Neutral90);
      }

      &:not(:disabled) {
        &:active,
        &:hover,
        &:focus {
          background-color: var(--findigs-BrandPrimary-Forest);
        }
      }

      svg {
        color: var(--findigs-BrandPrimary-Sand);
      }
    }
  }

  .fill-height {
    height: 100%;
  }

  div.applicant-identity-2 {
    margin-bottom: 20px;
  }

  h6.red-text {
    color: $status-unsuccessful;
  }

  div.applicant-identity-2,
  div.applicant-identity-3 {
    border-radius: 10px;

    .top-section {
      background-color: var(--findigs-BrandPrimary-Sand);
      padding-inline: 16px;
      padding-block: 20px 5px;
      padding-bottom: 5px;
      border-radius: 10px 10px 0px 0px;
      p {
        color: $grey;
        @include status-colors-text;
      }
    }

    .status-section {
      margin-block-start: 24px;
      padding-inline: 16px;

      &-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 5px;
        background-color: $status-successful;
        color: white;
        border-radius: 0px 0px 10px 10px;

        @include status-colors;
      }
    }
  }
  .warnings-status {
    &-success {
      color: $primary-green;
    }
    &-failure {
      color: $error-red;
    }
  }

  #cautionNotes {
    &:hover {
      text-decoration: underline;
    }
  }
}

.cautionNotes {
  .tooltip.show {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: var(--findigs-BrandPrimary-Sand);
    color: black;
    border: 1px solid gray;
  }

  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^='right'] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: gray;
  }
}
