@import 'variables-floop';
.Landing-Page {
  font-family: 'Findigs Sans';
  font-weight: $inter-bld-font-weight;
  font-size: $font-size-landing;
  line-height: $line-height-100;
  letter-spacing: $letter-spacing-5;
}

.Landing-Page-Subtext {
  font-family: 'Findigs Sans';
  font-weight: $inter-bld-font-weight;
  font-size: $font-size-landing-sub;
  line-height: $line-height-125;
  letter-spacing: $letter-spacing-5;
}

.Header-Text {
  font-family: 'Findigs Sans';
  font-weight: 900;
  font-size: 1.75rem;
  line-height: 2.125rem;
  letter-spacing: 0;

  &.PM-UI-28 {
    line-height: 94% !important;
  }
}

.Subheader-Text {
  font-family: 'Findigs Sans';
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0;
}
.Large-CTA,
.Med-CTA {
  font-family: 'Findigs Sans';
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0;
}

.Standard-Body {
  font-family: 'Findigs Sans';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0;
  a {
    font-weight: 500;
  }
}

.Med-Body {
  font-family: 'Findigs Sans';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  &.PM-UI-14 {
    line-height: 94% !important;
  }
}

.Legal-Copy {
  font-family: 'Findigs Sans';
  font-weight: $inter-med-font-weight;
  font-size: $font-size-med-body;
  line-height: $line-height-125;
  letter-spacing: $letter-spacing-3;
}

.Eyebrow-Text {
  font-family: 'Findigs Sans';
  font-weight: 400 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;

  &.PM-Pill {
    font-size: $font-size-status !important;
  }
}

.Tooltip-Body-Text {
  font-family: 'Findigs Sans';
  font-weight: $inter-semi-bld-font-weight;
  font-size: $font-size-tooltip-body;
  line-height: 165%;
  letter-spacing: $letter-spacing-3;
}

.Status-Text {
  font-family: 'Findigs Sans';
  font-weight: $inter-semi-bld-font-weight;
  font-size: $font-size-status;
  line-height: $line-height-125;
  letter-spacing: $letter-spacing-3;
}

.Tooltip-Body-Text {
  font-family: 'Findigs Sans';
  font-weight: $inter-semi-bld-font-weight;
  font-size: $font-size-tooltip-body;
  line-height: 165%;
  letter-spacing: $letter-spacing-3;
}

.PM-UI-12 {
  font-family: 'Findigs Sans';
  font-weight: $inter-med-font-weight;
  font-size: $font-size-eyebrows;
  line-height: $line-height-110;
  letter-spacing: $letter-spacing-3;

  &.bold {
    font-weight: 700;
  }
}

.PM-UI-14-bold {
  font-family: 'Findigs Sans';
  font-weight: $inter-bld-font-weight;
  font-size: $font-size-med-body;
  line-height: $line-height-110;
  letter-spacing: $letter-spacing-3;
}

.PM-UI-18 {
  font-family: 'Findigs Sans';
  font-weight: $inter-bld-font-weight;
  font-size: $font-size-med-cta;
  line-height: $line-height-110;
  letter-spacing: $letter-spacing-3;
}

.sentence-case {
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

.capital-case {
  text-transform: uppercase;
}
