.ReactTable.file-upload-table {
  .rt-th {
    background-color: white;
  }

  .rt-tbody {
    overflow: visible;

    .rt-tr-group {
      border-bottom: solid 1px #e9ecef;
      height: 55px;
    }

    .rt-tr {
      background-color: white;

      &:hover:not(.-padRow) {
        background-color: var(--findigs-Tints-Neutral10);
        cursor: pointer;
      }
    }
  }
}
