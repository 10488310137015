@import '~bootstrap/scss/mixins/buttons';
@import '../pmvariables';
@import '../colors-floop';

.pm-button {
  &.btn-md {
    @include button-size(
      $padding-y: 0.75rem,
      $padding-x: 1.5rem,
      $font-size: 16px,
      $border-radius: 4px
    );
  }
}

// This has to be defined here and not in the style module for Button b/c
// all the generated class names would be hashed.
.btn {
  opacity: 1;

  &-primary {
    @include button-variant(
      map-get($theme-colors, 'primary'),
      map-get($theme-colors, 'primary'),
      $hover-background: map-get($colors, 'indigo'),
      $active-background: map-get($colors, 'indigo')
    );
    &:active {
      background-color: map-get($colors, 'indigo');
    }
  }

  &-pm-primary {
    @include button-variant(
      $brandPrimaryForest,
      $brandPrimaryForest,
      $hover-background: $tintsNeutral90,
      $active-background: $tintsNeutral80
    );
    &:focus {
      background-color: $brandPrimaryForest;
      outline: 2px solid $brandPrimaryForest !important;
    }
    &:active {
      background-color: $brandPrimaryForest;
      outline: none !important;
    }
    &:disabled {
      background-color: $tintsNeutral40;
      border-color: $tintsNeutral40;
      color: $brandPrimaryForest;
    }
  }

  &-secondary {
    @include button-variant(
      map-get($theme-colors, 'primary'),
      map-get($theme-colors, 'primary'),
      $hover-background: map-get($colors, 'indigo'),
      $active-background: map-get($colors, 'indigo')
    );
  }

  &-outline-primary {
    &:focus,
    &:hover {
      color: map-get($colors, 'white');
      background-color: map-get($colors, 'indigo');
      border-color: map-get($colors, 'indigo');
    }
    background-color: var(--findigs-BrandPrimary-Sand);
  }

  &-outline-secondary {
    @include button-outline-variant(
      $color: $neutral100,
      $color-hover: $neutral80,
      $active-background: $neutral10,
      $active-border: $neutral80
    );

    // TODO: Make this apart of a sass function.
    &:focus {
      background-color: map-get($colors, 'gray-light');
    }

    &:disabled {
      background-color: $primaryWhite !important;
      color: $neutral60 !important;
      border-color: $neutral40 !important;
    }
  }

  &:disabled:not(.btn-link),
  &:disabled:not(.btn-link-nav),
  &.disabled:not(.btn-link) {
    @include button-variant(map-get($colors, 'gray'), map-get($colors, 'gray'));
  }

  &-link {
    @include button-variant(
      transparent,
      transparent,
      $color: $brandPrimaryForest,
      $hover-background: transparent,
      $hover-color: $tintsNeutral90,
      $disabled-color: $tintsNeutral20,
      $disabled-background: transparent
    );
    padding: unset;
  }

  &.circle-button {
    border-radius: 50%;
    padding: 2px 6px 2px 6px;
    font-size: $font-size-lg-cta;
    width: 40px;
    height: 40px;
  }

  &-link-nav {
    @include button-variant(
      $background: map-get($colors, 'white'),
      $border: transparent,
      $color: map-get($colors, 'blue'),
      $hover-background: map-get($colors, 'indigo'),
      $hover-color: map-get($colors, 'white'),
      $disabled-color: map-get($colors, 'gray'),
      $disabled-background: map-get($colors, 'white'),
      $active-background: map-get($colors, 'white'),
      $active-color: map-get($colors, 'gray')
    );
  }
  &-link-transparent {
    @include button-variant(
      $background: transparent,
      $border: transparent,
      $color: map-get($colors, 'blue'),
      $hover-background: map-get($colors, 'indigo'),
      $hover-color: map-get($colors, 'white'),
      $disabled-color: map-get($colors, 'gray'),
      $disabled-background: map-get($colors, 'white'),
      $active-background: map-get($colors, 'white'),
      $active-color: map-get($colors, 'gray')
    );
  }
}
