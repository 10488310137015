@font-face {
  font-family: 'Findigs Sans';
  src: url('./../fonts/findigs-sans-regular.woff2') format('woff2'),
    url('./../fonts/findigs-sans-regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Findigs Sans';
  src: url('./../fonts/findigs-sans-medium.woff2') format('woff2'),
    url('./../fonts/findigs-sans-medium.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Findigs Sans';
  src: url('./../fonts/findigs-sans-bold.woff2') format('woff2'),
    url('./../fonts/findigs-sans-bold.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Findigs Sans';
  src: url('./../fonts/findigs-sans-black.woff2') format('woff2'),
    url('./../fonts/findigs-sans-black.otf') format('opentype');
  font-weight: 900;
  font-display: swap;
}
