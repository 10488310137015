@media print {
  body {
    zoom: 0.65;
  }
}

.landlord.container {
  @media print {
    .page-break-after {
    }

    .page-break-before {
    }

    .avoid-page-break {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }

    .row.block-on-print {
      display: block;
    }
  }

  @media print {
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
      float: left;
    }

    .col-md-1 {
      flex: 0 0 8%;
      max-width: 8%;
    }
    .col-md-2 {
      flex: 0 0 16%;
      max-width: 16%;
    }
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      flex: 0 0 33%;
      max-width: 33%;
    }
    .col-md-5 {
      flex: 0 0 42%;
      max-width: 42%;
    }
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      flex: 0 0 58%;
      max-width: 58%;
    }
    .col-md-8 {
      flex: 0 0 66%;
      max-width: 66%;
    }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      flex: 0 0 83%;
      max-width: 83%;
    }
    .col-md-11 {
      flex: 0 0 92%;
      max-width: 92%;
    }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
